import React, { useState, useEffect } from "react";
import axios from "axios";

const AddCampaignModal = ({ isOpen, onClose, onAddCampaign }) => {
  const [nombre, setNombre] = useState("");
  const [error, setError] = useState("");
  const [isValid, setIsValid] = useState(false);
  const [existingCampaigns, setExistingCampaigns] = useState([]);

  useEffect(() => {
    // Cargar las campañas existentes al abrir el modal
    if (isOpen) {
      fetchExistingCampaigns();
    }
  }, [isOpen]);

  const fetchExistingCampaigns = async () => {
    try {
      const response = await axios.get("/api/campaigns/get_campaigns.php");
      setExistingCampaigns(
        response.data.campaigns.map((campaign) => campaign.nombre.toLowerCase())
      );
    } catch (error) {
      console.error("Error fetching existing campaigns:", error);
    }
  };

  const validateNombre = (value) => {
    // Expresión regular para validar el nombre (solo letras, números y guiones medios)
    const regex = /^[a-zA-Z0-9-]+$/;

    if (value.trim() === "") {
      setError("El nombre de la campaña no puede estar vacío.");
      setIsValid(false);
    } else if (!regex.test(value)) {
      setError(
        "El nombre solo puede contener letras, números y guiones medios."
      );
      setIsValid(false);
    } else if (existingCampaigns.includes(value.toLowerCase())) {
      setError("Ya existe una campaña con este nombre.");
      setIsValid(false);
    } else {
      setError("");
      setIsValid(true);
    }
  };

  const handleInputChange = (e) => {
    const value = e.target.value.replace(/\s/g, ""); // Eliminar espacios en tiempo real
    setNombre(value);
    validateNombre(value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isValid) {
      onAddCampaign({ nombre });
      setNombre("");
      setError("");
      setIsValid(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h2>Agregar Nueva Campaña</h2>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="nombre">Nombre de la Campaña:</label>
            <input
              className="inputMango"
              type="text"
              id="nombre"
              value={nombre}
              onChange={handleInputChange}
              required
            />
            {error && <p className="error-message">{error}</p>}
          </div>
          <div className="form-actions">
            <button type="submit" className="button" disabled={!isValid}>
              Agregar
            </button>
            <button
              type="button"
              className="button secondary cancel"
              onClick={onClose}
            >
              Cancelar
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddCampaignModal;

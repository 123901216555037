import React, { useState, useCallback } from "react";
import axios from "axios";
import Sidebar from "../components/Sidebar";
import LeadDetailView from "./LeadDetailView";

const SearchLeads = () => {
  const [leads, setLeads] = useState([]);
  const [campaigns, setCampaigns] = useState([]);
  const [selectedCampaign, setSelectedCampaign] = useState("all");
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedLead, setSelectedLead] = useState(null);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [totalLeads, setTotalLeads] = useState(0);
  const [hasSearched, setHasSearched] = useState(false);

  const fetchLeads = useCallback(
    async (page = 1) => {
      setIsLoading(true);
      try {
        const response = await axios.get(
          `/api/leads/find_lead.php?page=${page}&campaign=${selectedCampaign}&searchTerm=${searchTerm}&t=${new Date().getTime()}`
        );
        setLeads(response.data.leads);
        setTotalPages(response.data.totalPages);
        setTotalLeads(response.data.totalLeads);
        setCampaigns(response.data.campaigns);
        setHasSearched(true);
      } catch (error) {
        console.error("Error fetching leads:", error);
      } finally {
        setIsLoading(false);
      }
    },
    [selectedCampaign, searchTerm]
  );

  const handleSearch = (e) => {
    e.preventDefault();
    setCurrentPage(1);
    fetchLeads(1);
  };

  const handleViewLead = (lead) => {
    setSelectedLead(lead);
  };

  const handleBackToSearch = () => {
    setSelectedLead(null);
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const truncateText = (text, maxLength) => {
    if (!text || text.length <= maxLength) {
      return text || "N/A";
    }
    return text.substring(0, maxLength) + "...";
  };

  if (selectedLead) {
    return <LeadDetailView lead={selectedLead} onBack={handleBackToSearch} />;
  }

  return (
    <div className="app-container">
      <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
      <div className={`main-content ${isSidebarOpen ? "sidebar-open" : ""}`}>
        <div className="search-leads-container">
          <h1>Buscar Leads</h1>
          <form onSubmit={handleSearch} className="search-form">
            <div className="selector">
              <select
                value={selectedCampaign}
                onChange={(e) => setSelectedCampaign(e.target.value)}
              >
                <option value="all">Todas las campañas</option>
                {campaigns.map((campaign) => (
                  <option key={campaign} value={campaign}>
                    {campaign}
                  </option>
                ))}
              </select>
              <div className="selectArrowForm"></div>
            </div>
            <input
              type="text"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              placeholder="Buscar en datos recibidos..."
              required
            />
            <button type="submit" className="button" disabled={isLoading}>
              Buscar
            </button>
          </form>
          {hasSearched && (
            <p className="search-results-message">
              <strong>
                Total de leads encontrados: {totalLeads.toLocaleString()}
              </strong>
            </p>
          )}
          {hasSearched && (
            <div
              className={!isLoading ? "table-leads" : "table-leads over-hidden"}
            >
              {isLoading && (
                <div className="loader-table">
                  <h3>Cargando...</h3>
                </div>
              )}
              {!isLoading && leads.length === 0 ? (
                <p>No se encontraron resultados para tu búsqueda.</p>
              ) : (
                <table>
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Campaña</th>
                      <th>Fecha</th>
                      <th>Data</th>
                      <th>Respuesta CRM</th>
                      <th>Status</th>
                      <th>Acción</th>
                    </tr>
                  </thead>
                  <tbody>
                    {leads.map((lead) => (
                      <tr key={lead.id}>
                        <td>{lead.id}</td>
                        <td>{lead.campana}</td>
                        <td>
                          {lead.fecha
                            ? new Date(lead.fecha).toLocaleString()
                            : "N/A"}
                        </td>
                        <td>{truncateText(lead.recibido, 50)}</td>
                        <td>{truncateText(lead.respuesta_enviado, 30)}</td>
                        <td>
                          <span
                            className={`status-badge ${
                              lead.status === 1
                                ? "status-success"
                                : "status-pending"
                            }`}
                          >
                            {lead.status === 1 ? "Recibido" : "Pendiente"}
                          </span>
                        </td>
                        <td>
                          <button
                            className="button"
                            onClick={() => handleViewLead(lead)}
                          >
                            Ver
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </div>
          )}
          {hasSearched && totalPages > 1 && (
            <div className="pagination">
              <button
                onClick={() => {
                  setCurrentPage((prev) => Math.max(prev - 1, 1));
                  fetchLeads(Math.max(currentPage - 1, 1));
                }}
                disabled={currentPage === 1 || isLoading}
              >
                Anterior
              </button>
              <span>
                Página {currentPage} de {totalPages}
              </span>
              <button
                onClick={() => {
                  setCurrentPage((prev) => Math.min(prev + 1, totalPages));
                  fetchLeads(Math.min(currentPage + 1, totalPages));
                }}
                disabled={currentPage === totalPages || isLoading}
              >
                Siguiente
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SearchLeads;

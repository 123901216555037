import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { DateRange } from "react-date-range";
import { startOfMonth, endOfMonth } from "date-fns";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import Sidebar from "../components/Sidebar";
import AddCampaignModal from "../components/AddCampaign";

const Campaigns = () => {
  const [campanas, setCampanas] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [isFocusDate, setIsFocusDate] = useState(false);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(false);

  const [dateRange, setDateRange] = useState([
    {
      startDate: startOfMonth(new Date()),
      endDate: endOfMonth(new Date()),
      key: "selection",
    },
  ]);
  const [isCustomDateRange, setIsCustomDateRange] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event) => {
      const dateRangePicker = document.querySelector(".date-range-picker");
      if (dateRangePicker && !dateRangePicker.contains(event.target)) {
        setIsFocusDate(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const fetchCampanas = useCallback(async (range) => {
    setIsLoading(true);
    try {
      const startDate = range.startDate.toISOString().split("T")[0];
      const endDate = range.endDate.toISOString().split("T")[0];
      const response = await axios.get(
        `/api/campaigns/get_campaigns.php?startDate=${startDate}&endDate=${endDate}&t=${new Date().getTime()}`
      );
      
      // Calcula el número de días en el rango
      const days = Math.ceil((range.endDate - range.startDate) / (1000 * 60 * 60 * 24)) + 1;
      
      const sortedCampanas = response.data.campaigns.map(campana => ({
        ...campana,
        avgLeads: (campana.totalLeads - (campana.pendingLeads || 0)) / days
      })).sort((a, b) => b.totalLeads - a.totalLeads);
      
      setCampanas(sortedCampanas);
      console.log("Campanas State:", sortedCampanas);
    } catch (error) {
      console.error("Error fetching campanas:", error);
    } finally {
      setIsLoading(false);
      setIsRefreshing(false);
    }
  }, []);

  useEffect(() => {
    fetchCampanas(dateRange[0]);
  }, [fetchCampanas, dateRange]);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleDateRangeChange = (item) => {
    setDateRange([item.selection]);
    setIsCustomDateRange(true);
    fetchCampanas(item.selection);
  };

  const resetToCurrentMonth = () => {
    const currentMonthRange = {
      startDate: startOfMonth(new Date()),
      endDate: endOfMonth(new Date()),
      key: "selection",
    };
    setDateRange([currentMonthRange]);
    setIsCustomDateRange(false);
    fetchCampanas(currentMonthRange);
  };

  const openAddModal = () => setIsAddModalOpen(true);
  const closeAddModal = () => setIsAddModalOpen(false);

  const handleAddCampaign = async (newCampaign) => {
    try {
      const response = await axios.post(
        "/api/campaigns/add_campaign.php",
        newCampaign
      );
      if (response.data.success) {
        fetchCampanas(dateRange[0]);
        closeAddModal();
      } else {
        console.error("Error al agregar la campaña:", response.data.message);
      }
    } catch (error) {
      console.error("Error al agregar la campaña:", error);
    }
  };

  const handleRefresh = useCallback(() => {
    setIsRefreshing(true);
    fetchCampanas(dateRange[0]);
  }, [fetchCampanas, dateRange]);

  return (
    <div className="app-container">
      <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
      <div
        className={`main-content campaigns-content ${
          isSidebarOpen ? "sidebar-open" : ""
        }`}
      >
        <h1>
          Campañas del {isCustomDateRange ? "Rango Seleccionado" : "Mes Actual"}
        </h1>
        <div className="campaigns-header">
          <div className="date-range-picker">
            <DateRange
              editableDateInputs={true}
              onChange={handleDateRangeChange}
              moveRangeOnFirstSelection={false}
              ranges={dateRange}
              color={"#07ea93"}
              onRangeFocusChange={() => {
                setIsFocusDate(() => true);
              }}
              className={
                "date-range-picker" + (isFocusDate ? " focusDate" : "")
              }
            />
            <div className="control-buttons">
              {isCustomDateRange && (
                <button className="button" onClick={resetToCurrentMonth}>
                  Volver al Mes Actual
                </button>
              )}
              <button
                className="button"
                onClick={handleRefresh}
                disabled={isRefreshing}
              >
                {isRefreshing ? "Actualizando..." : "Actualizar Campañas"}
              </button>
              <button
                className="button"
                onClick={openAddModal}
              >
                Nueva Campaña
              </button>
            </div>
          </div>
        </div>
        {isLoading ? (
          <p>Cargando campañas...</p>
        ) : (
          <div className="table-leads">
            <table>
              <thead>
                <tr>
                  <th>Campaña</th>
                  <th>Recibidos</th>
                  <th>Pendientes</th>
                  <th>AVG</th>
                </tr>
              </thead>
              <tbody>
                {campanas.map((campana) => (
                  <tr key={campana.id}>
                    <td>
                      <Link
                        to={`/campaign/${encodeURIComponent(campana.nombre)}`}
                      >
                        {campana.nombre}
                      </Link>
                    </td>
                    <td>{campana.totalLeads}</td>
                    <td>
                      {campana.pendingLeads !== undefined
                        ? campana.pendingLeads
                        : "N/A"}
                    </td>
                    <td>{campana.avgLeads !== undefined ? Math.round(campana.avgLeads) : "N/A"}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
      <AddCampaignModal
        isOpen={isAddModalOpen}
        onClose={closeAddModal}
        onAddCampaign={handleAddCampaign}
        existingCampaigns={campanas.map((c) => c.nombre)}
      />
    </div>
  );
};

export default Campaigns;
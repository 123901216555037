import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { DateRange } from "react-date-range";
import { startOfMonth, endOfMonth } from "date-fns";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import LeadDetailView from "./LeadDetailView";
import Sidebar from "../components/Sidebar";
import LineChart from "../components/LineChart";

const CampaignLeads = () => {
  // State declarations
  const [leads, setLeads] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedLead, setSelectedLead] = useState(null);
  const [lastUpdateTime, setLastUpdateTime] = useState(null);
  const [totalLeads, setTotalLeads] = useState(0);
  const [pendingLeads, setPendingLeads] = useState(0);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [leadsByDay, setLeadsByDay] = useState([]);
  const [monthlyLeadsData, setMonthlyLeadsData] = useState([]);
  const [sortField, setSortField] = useState("fecha");
  const [sortDirection, setSortDirection] = useState("desc");
  const [dateRange, setDateRange] = useState([
    {
      startDate: startOfMonth(new Date()),
      endDate: endOfMonth(new Date()),
      key: "selection",
    },
  ]);
  const [isCustomDateRange, setIsCustomDateRange] = useState(false);
  const [isFocusDate, setIsFocusDate] = useState(false);
  const [averageLeads, setAverageLeads] = useState(0);

  const { campaignName } = useParams();
  const navigate = useNavigate();

  // API calls
  const fetchLeads = useCallback(
    async (page, range, sort = {}) => {
      setIsLoading(true);
      try {
        const startDate = range.startDate.toISOString().split("T")[0];
        const endDate = range.endDate.toISOString().split("T")[0];
        const response = await axios.get(
          `/api/leads/get_leads.php?api_key=mangoCia_2024_APIKEY_8f6a7d9e4c3b2a1&page=${page}&campaign=${encodeURIComponent(
            campaignName
          )}&startDate=${startDate}&endDate=${endDate}&sortField=${
            sort.field || ""
          }&sortDirection=${sort.direction || ""}&t=${new Date().getTime()}`
        );
        
        setLeads(response.data.leads);
        setTotalPages(response.data.totalPages);
        setLastUpdateTime(response.data.timestamp);
        setTotalLeads(response.data.totalLeads);
        setPendingLeads(response.data.pendingLeads);

        const leadsByDayProcessed = response.data.leadsByDay.map((item) => ({
          day: new Date(item.day).getDate(),
          received_count: parseInt(item.received_count),
          pending_count: parseInt(item.pending_count),
        }));
        setLeadsByDay(leadsByDayProcessed);

        // Calculate average leads
        const startDateObj = new Date(range.startDate);
        const endDateObj = new Date(range.endDate);
        const daysDiff = Math.ceil((endDateObj - startDateObj) / (1000 * 60 * 60 * 24)) + 1;
        const receivedLeads = response.data.totalLeads - response.data.pendingLeads;
        const average = receivedLeads / daysDiff;
        setAverageLeads(average);
      } catch (error) {
        console.error("Error fetching leads:", error);
      } finally {
        setIsLoading(false);
      }
    },
    [campaignName]
  );

  const fetchMonthlyLeads = useCallback(async () => {
    try {
      const response = await axios.get(
        `/api/leads/get_monthly_leads.php?api_key=mangoCia_2024_APIKEY_8f6a7d9e4c3b2a1&campaign=${encodeURIComponent(campaignName)}`
      );
      setMonthlyLeadsData(response.data.monthlyData);
    } catch (error) {
      console.error("Error fetching monthly leads:", error);
    }
  }, [campaignName]);

  // Effects
  useEffect(() => {
    fetchLeads(currentPage, dateRange[0], {
      field: sortField,
      direction: sortDirection,
    });
    fetchMonthlyLeads();
  }, [currentPage, fetchLeads, fetchMonthlyLeads, dateRange, sortField, sortDirection]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      const dateRangePicker = document.querySelector(".date-range-picker");
      if (dateRangePicker && !dateRangePicker.contains(event.target)) {
        setIsFocusDate(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Event handlers
  const handleViewLead = useCallback((lead) => {
    setSelectedLead(lead);
  }, []);

  const handleBackToDashboard = useCallback(() => {
    setSelectedLead(null);
  }, []);

  const handleRefresh = useCallback(() => {
    fetchLeads(currentPage, dateRange[0], {
      field: sortField,
      direction: sortDirection,
    });
    fetchMonthlyLeads();
  }, [currentPage, fetchLeads, fetchMonthlyLeads, dateRange, sortField, sortDirection]);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleSort = (field) => {
    const newDirection =
      field === sortField && sortDirection === "asc" ? "desc" : "asc";
    setSortDirection(newDirection);
    setSortField(field);
    setCurrentPage(1);
    fetchLeads(1, dateRange[0], { field, direction: newDirection });
  };

  const handleDateRangeChange = (item) => {
    setDateRange([item.selection]);
    setIsCustomDateRange(true);
    setCurrentPage(1);
    fetchLeads(1, item.selection, {
      field: sortField,
      direction: sortDirection,
    });
  };

  const resetToCurrentMonth = () => {
    const currentMonthRange = {
      startDate: startOfMonth(new Date()),
      endDate: endOfMonth(new Date()),
      key: "selection",
    };
    setDateRange([currentMonthRange]);
    setIsCustomDateRange(false);
    setCurrentPage(1);
    fetchLeads(1, currentMonthRange, {
      field: sortField,
      direction: sortDirection,
    });
  };

  // Helper functions
  const truncateText = (text, maxLength) => {
    if (!text || text.length <= maxLength) {
      return text || "N/A";
    }
    return text.substring(0, maxLength) + "...";
  };

  const exportToCSV = useCallback(() => {
    const csvRows = [];
    const headers = ["ID", "Fecha", "Data", "Respuesta CRM", "Status"];
    csvRows.push(headers.join(","));

    leads.forEach((lead) => {
      const formatJSON = (json) => {
        try {
          const obj = typeof json === "string" ? JSON.parse(json) : json;
          return JSON.stringify(obj).replace(/"/g, '""');
        } catch (e) {
          return String(json).replace(/"/g, '""');
        }
      };

      const row = [
        lead.id,
        lead.fecha ? new Date(lead.fecha).toLocaleString() : "N/A",
        `"${formatJSON(lead.recibido)}"`,
        `"${formatJSON(lead.respuesta_enviado)}"`,
        lead.status === 1 ? "Recibido" : "Pendiente",
      ];
      csvRows.push(row.join(","));
    });

    const csvContent = `data:text/csv;charset=utf-8,${csvRows.join("\n")}`;
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute(
      "download",
      `leads_${campaignName}_${new Date().toISOString().split("T")[0]}.csv`
    );
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }, [leads, campaignName]);

  // Memoized values
  const chartData = useMemo(
    () => ({
      labels: leadsByDay.map((item) => item.day),
      datasets: [
        {
          label: "Recibidos por Día",
          data: leadsByDay.map((item) => item.received_count),
          backgroundColor: "rgba(75, 192, 192, 0.2)",
          borderColor: "rgba(7, 234, 147, 1)",
          pointBackgroundColor: "rgba(7, 234, 147, 1)",
          pointBorderColor: "#07ea93",
          borderWidth: 2,
          tension: 0.4,
          fill: false,
        },
        {
          label: "Pendientes por Día",
          data: leadsByDay.map((item) => item.pending_count),
          backgroundColor: "rgba(192, 75, 75, 0.2)",
          borderColor: "rgba(234, 7, 7, 1)",
          pointBackgroundColor: "rgba(234, 7, 7, 1)",
          pointBorderColor: "#ea0707",
          borderWidth: 2,
          tension: 0.4,
          fill: false,
        },
      ],
    }),
    [leadsByDay]
  );

  const monthlyChartData = useMemo(() => ({
    labels: monthlyLeadsData.map(item => item.month),
    datasets: [
      {
        label: 'Recibidos por Mes',
        data: monthlyLeadsData.map(item => item.receivedLeads),
        backgroundColor: "rgba(75, 192, 192, 0.2)",
          borderColor: "rgba(7, 234, 147, 1)",
          pointBackgroundColor: "rgba(7, 234, 147, 1)",
          pointBorderColor: "#07ea93",
          borderWidth: 2,
          tension: 0.4,
          fill: false,
      },
      {
        label: 'Pendientes por Mes',
        data: monthlyLeadsData.map(item => item.pendingLeads),
        backgroundColor: "rgba(192, 75, 75, 0.2)",
          borderColor: "rgba(234, 7, 7, 1)",
          pointBackgroundColor: "rgba(234, 7, 7, 1)",
          pointBorderColor: "#ea0707",
          borderWidth: 2,
          tension: 0.4,
          fill: false,
      }
    ],
  }), [monthlyLeadsData]);

  const options = useMemo(
    () => ({
      scales: {
        y: {
          beginAtZero: true,
        },
      },
      responsive: true,
      maintainAspectRatio: false,
    }),
    []
  );

  // Render
  if (selectedLead) {
    return (
      <LeadDetailView lead={selectedLead} onBack={handleBackToDashboard} />
    );
  }

  return (
    <div className="app-container">
      <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
      <div className={`main-content ${isSidebarOpen ? "sidebar-open" : ""}`}>
        <div className="header-table">
          <h1>Leads de la Campaña: {decodeURIComponent(campaignName)}</h1>
          <div className="date-range-picker">
            <DateRange
              editableDateInputs={true}
              onChange={handleDateRangeChange}
              moveRangeOnFirstSelection={false}
              ranges={dateRange}
              color={"#07ea93"}
              onRangeFocusChange={() => {
                setIsFocusDate(() => true);
              }}
              className={
                "date-range-picker" + (isFocusDate ? " focusDate" : "")
              }
            />
          </div>
          <div className="control-buttons">
            {isCustomDateRange && (
              <button className="button" onClick={resetToCurrentMonth}>
                Ver Mes Actual
              </button>
            )}
            <button
              className="button"
              onClick={handleRefresh}
              disabled={isLoading}
            >
              {isLoading ? "Actualizando..." : "Actualizar Datos"}
            </button>
            <button className="button" onClick={() => navigate("/dashboard")}>
              Volver al Dashboard
            </button>
            <button className="button" onClick={exportToCSV}>
              Exportar
            </button>
          </div>
          <p>
            <strong>
              Recibidos {totalLeads.toLocaleString()} / Pendientes {pendingLeads.toLocaleString()} / AVG {Math.round(averageLeads)}
            </strong>
          </p>
          <div className="chart-container">
  {leadsByDay.length > 0 ? (
    <LineChart data={chartData} options={{
      ...options,
      plugins: {
        title: {
          display: true,
          text: 'Leads por día',
        },
      },
    }} />
  ) : (
    <p>No hay datos disponibles para el gráfico diario</p>
  )}
</div>
          <div className="chart-container">
            {monthlyLeadsData.length > 0 ? (
              <LineChart data={monthlyChartData} options={{
                ...options,
                plugins: {
                  title: {
                    display: true,
                    text: 'Leads Mensuales (Últimos 6 meses)',
                  },
                },
              }} />
            ) : (
              <p>No hay datos mensuales disponibles</p>
            )}
          </div>
        </div>
        <div className={!isLoading ? "table-leads" : "table-leads over-hidden"}>
          {isLoading && (
            <div className="loader-table">
              <h3>Cargando...</h3>
            </div>
          )}
          <table>
            <thead>
              <tr>
                <th>ID</th>
                <th>
                  Fecha
                  <a href="#" onClick={() => handleSort("fecha")}>
                    {sortField === "fecha"
                      ? sortDirection === "asc"
                        ? "▲"
                        : "▼"
                      : "▲▼"}
                  </a>
                </th>
                <th>Data</th>
                <th>Respuesta CRM</th>
                <th>
                  Status
                  <a href="#" onClick={() => handleSort("status")}>
                    {sortField === "status"
                      ? sortDirection === "asc"
                        ? "▲"
                        : "▼"
                      : "▲▼"}
                  </a>
                </th>
                <th>Acción</th>
              </tr>
            </thead>
            <tbody>
              {leads.map((lead) => (
                <tr key={lead.id}>
                  <td>{lead.id}</td>
                  <td>
                    {lead.fecha ? new Date(lead.fecha).toLocaleString() : "N/A"}
                  </td>
                  <td>{truncateText(lead.recibido, 50)}</td>
                  <td>{truncateText(lead.respuesta_enviado, 30)}</td>
                  <td>
                    <span
                      className={`status-badge ${
                        lead.status === 1 ? "status-success" : "status-pending"
                      }`}
                    >
                      {lead.status === 1 ? "Recibido" : "Pendiente"}
                    </span>
                  </td>
                  <td>
                    <button
                      className="button"
                      onClick={() => handleViewLead(lead)}
                    >
                      Ver
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="pagination">
          <button
            onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
            disabled={currentPage === 1 || isLoading}
          >
            Anterior
          </button>
          <span>
            Página {currentPage} de {totalPages}
          </span>
          <button
            onClick={() =>
              setCurrentPage((prev) => Math.min(prev + 1, totalPages))
            }
            disabled={currentPage === totalPages || isLoading}
          >
            Siguiente
          </button>
        </div>
      </div>
    </div>
  );
};

export default CampaignLeads;
import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef,
} from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { DateRange } from "react-date-range";
import { startOfMonth, endOfMonth } from "date-fns";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import LeadDetailView from "./LeadDetailView";
import Sidebar from "../components/Sidebar";
import LineChart from "../components/LineChart";

const Dashboard = () => {
  const [leads, setLeads] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedLead, setSelectedLead] = useState(null);
  const [lastUpdateTime, setLastUpdateTime] = useState(null);
  const [totalLeads, setTotalLeads] = useState(0);
  const [pendingLeads, setPendingLeads] = useState(0);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [leadsByDay, setLeadsByDay] = useState([]);
  const [error, setError] = useState(null);
  const [isFocusDate, setIsFocusDate] = useState(false);
  const navigate = useNavigate();
  const [dateRange, setDateRange] = useState([
    {
      startDate: startOfMonth(new Date()),
      endDate: endOfMonth(new Date()),
      key: "selection",
    },
  ]);
  const [isCustomDateRange, setIsCustomDateRange] = useState(false);
  const [sortField, setSortField] = useState("fecha");
  const [sortDirection, setSortDirection] = useState("desc");

  useEffect(() => {
    const token = localStorage.getItem("token");
    console.log("Token:", token);
    if (!token) {
      navigate("/login");
    }
  }, [navigate]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      const dateRangePicker = document.querySelector(".date-range-picker");
      if (dateRangePicker && !dateRangePicker.contains(event.target)) {
        setIsFocusDate(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const fetchLeads = useCallback(async (page, range, sort = {}) => {
    setIsLoading(true);
    setError(null);
    try {
      const startDate = range.startDate.toISOString().split("T")[0];
      const endDate = range.endDate.toISOString().split("T")[0];
      const response = await axios.get(
        `/api/leads/get_leads.php?api_key=mangoCia_2024_APIKEY_8f6a7d9e4c3b2a1&page=${page}&startDate=${startDate}&endDate=${endDate}&sortField=${
          sort.field || ""
        }&sortDirection=${sort.direction || ""}&t=${new Date().getTime()}`
      );
      setLeads(response.data.leads);
      setTotalPages(response.data.totalPages);
      setLastUpdateTime(response.data.timestamp);
      setTotalLeads(response.data.totalLeads);
      setPendingLeads(response.data.pendingLeads);
      setLeadsByDay(response.data.leadsByDay);
    } catch (error) {
      console.error("Error fetching leads:", error);
      setError(
        "Hubo un problema al cargar los datos. Por favor, intente de nuevo."
      );
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchLeads(currentPage, dateRange[0], {
      field: sortField,
      direction: sortDirection,
    });
  }, [currentPage, fetchLeads, dateRange, sortField, sortDirection]);

  const handleRefresh = useCallback(() => {
    fetchLeads(currentPage, dateRange[0], {
      field: sortField,
      direction: sortDirection,
    });
  }, [currentPage, fetchLeads, dateRange, sortField, sortDirection]);

  const handleViewLead = useCallback((lead) => {
    setSelectedLead(lead);
  }, []);

  const handleBackToDashboard = useCallback(() => {
    setSelectedLead(null);
  }, []);

  const toggleSidebar = useCallback(() => {
    setIsSidebarOpen((prev) => !prev);
  }, []);

  const handleDateRangeChange = (item) => {
    setDateRange([item.selection]);
    setIsCustomDateRange(true);
    setCurrentPage(1);
    fetchLeads(1, item.selection, {
      field: sortField,
      direction: sortDirection,
    });
  };

  const resetToCurrentMonth = () => {
    const currentMonthRange = {
      startDate: startOfMonth(new Date()),
      endDate: endOfMonth(new Date()),
      key: "selection",
    };
    setDateRange([currentMonthRange]);
    setIsCustomDateRange(false);
    setCurrentPage(1);
    fetchLeads(1, currentMonthRange, {
      field: sortField,
      direction: sortDirection,
    });
  };

  const handleSort = (field) => {
    const newDirection =
      field === sortField && sortDirection === "asc" ? "desc" : "asc";
    setSortDirection(newDirection);
    setSortField(field);
    setCurrentPage(1);
    fetchLeads(1, dateRange[0], { field, direction: newDirection });
  };

  const tableLeadsRef = useRef(null);

  useEffect(() => {
    if (tableLeadsRef.current) {
      tableLeadsRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [currentPage]);

  const chartData = useMemo(
    () => ({
      labels: leadsByDay.map((item) => new Date(item.day).getDate()),
      datasets: [
        {
          label: "Ingresados",
          data: leadsByDay.map((item) => item.received_count),
          backgroundColor: "rgba(75, 192, 192, 0.2)",
          borderColor: "rgba(7, 234, 147, 1)",
          pointBackgroundColor: "rgba(7, 234, 147, 1)",
          pointBorderColor: "#07ea93",
          borderWidth: 2,
          tension: 0.4,
          fill: false,
        },
        {
          label: "Pendientes",
          data: leadsByDay.map((item) => item.pending_count),
          backgroundColor: "rgba(255, 99, 132, 0.2)",
          borderColor: "rgba(255, 99, 132, 1)",
          pointBackgroundColor: "rgba(255, 99, 132, 1)",
          pointBorderColor: "#FF6384",
          borderWidth: 2,
          tension: 0.4,
          fill: false,
        },
      ],
    }),
    [leadsByDay]
  );

  const options = useMemo(
    () => ({
      scales: {
        y: {
          beginAtZero: true,
        },
      },
      responsive: true,
      maintainAspectRatio: false,
    }),
    []
  );

  if (selectedLead) {
    return (
      <LeadDetailView lead={selectedLead} onBack={handleBackToDashboard} />
    );
  }

  return (
    <div className="app-container">
      <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
      <div className={`main-content ${isSidebarOpen ? "sidebar-open" : ""}`}>
        <div className="header-table">
          <h1>
            Leads del {isCustomDateRange ? "Rango Seleccionado" : "Mes Actual"}
          </h1>
          <div className="date-range-picker">
            <DateRange
              editableDateInputs={true}
              onChange={handleDateRangeChange}
              moveRangeOnFirstSelection={false}
              ranges={dateRange}
              color={"#07ea93"}
              onRangeFocusChange={() => {
                setIsFocusDate(() => true);
              }}
              className={
                "date-range-picker" + (isFocusDate ? " focusDate" : "")
              }
            />
          </div>
          <div className="control-buttons">
            {isCustomDateRange && (
              <button className="button" onClick={resetToCurrentMonth}>
                Volver al Mes Actual
              </button>
            )}
            <button
              className="button"
              onClick={handleRefresh}
              disabled={isLoading}
            >
              {isLoading ? "Actualizando..." : "Actualizar Datos"}
            </button>
          </div>
          <p>
            <strong>
              Total de leads del período: {totalLeads.toLocaleString()} (
              {pendingLeads.toLocaleString()} Pendientes)
            </strong>
          </p>
          {error && <div className="error-message">{error}</div>}
          <div className="chart-container">
            {leadsByDay.length > 0 ? (
              <LineChart data={chartData} options={options} />
            ) : (
              <p>No hay datos disponibles para el gráfico</p>
            )}
          </div>
        </div>
        <div
          className={!isLoading ? "table-leads" : "table-leads over-hidden"}
          ref={tableLeadsRef}
        >
          {isLoading && (
            <div className="loader-table">
              <h3>Cargando...</h3>
            </div>
          )}
          <table>
            <thead>
              <tr>
                <th>ID</th>
                <th>Campaña</th>
                <th>
                  Fecha
                  <a href="#" onClick={() => handleSort("fecha")}>
                    {sortField === "fecha"
                      ? sortDirection === "asc"
                        ? "▲"
                        : "▼"
                      : "▲▼"}
                  </a>
                </th>
                <th>
                  Status
                  <a href="#" onClick={() => handleSort("status")}>
                    {sortField === "status"
                      ? sortDirection === "asc"
                        ? "▲"
                        : "▼"
                      : "▲▼"}
                  </a>
                </th>
                <th>Acción</th>
              </tr>
            </thead>
            <tbody>
              {leads.map((lead) => (
                <tr key={lead.id}>
                  <td>{lead.id}</td>
                  <td>
                    <Link to={`/campaign/${encodeURIComponent(lead.campana)}`}>
                      {lead.campana}
                    </Link>
                  </td>
                  <td>{new Date(lead.fecha).toLocaleString()}</td>
                  <td>
                    <span
                      className={`status-badge ${
                        lead.status === 1 ? "status-success" : "status-pending"
                      }`}
                    >
                      {lead.status === 1 ? "Recibido" : "Pendiente"}
                    </span>
                  </td>
                  <td>
                    <button
                      className="button"
                      onClick={() => handleViewLead(lead)}
                    >
                      Ver
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="pagination">
          <button
            onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
            disabled={currentPage === 1 || isLoading}
          >
            Anterior
          </button>
          <span>
            Página {currentPage} de {totalPages}
          </span>
          <button
            onClick={() =>
              setCurrentPage((prev) => Math.min(prev + 1, totalPages))
            }
            disabled={currentPage === totalPages || isLoading}
          >
            Siguiente
          </button>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;

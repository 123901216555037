import React from "react";
import { Link } from "react-router-dom";

const Sidebar = ({ isOpen, toggleSidebar }) => {
  const handleLogout = () => {
    localStorage.removeItem("token");
    window.location.href = "/login";
  };
  return (
    <div className={`sidebar ${isOpen ? "open" : ""}`}>
      <button className="toggle-btn" onClick={toggleSidebar}>
        <div className={`selectArrow${isOpen ? " open-arrrow" : ""}`}></div>
      </button>
      {isOpen && (
        <>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 664 692"
            colorInterpolation="sRGB"
            className="logoSide"
          >
            <path
              fill={"#fff"}
              d="M63.16 1.362c-6.684 23.344 17.037 101.946 17.037 101.946s-9.423-30.003-34.501-27.39c-18.947 1.985-31.838 15.429-34.753 34.25-4.875 31.461 43.07 57.67 73.2 22.239 26.485-33.245 18.343-100.112 8.392-121.32C86.731-1.302 63.487.055 63.185 1.337l-.025.025zM318.843 691.465c-39.98 0-97.851-15.152-176.352-70.158C54.214 559.44 20.819 485.387 8.33 434.15c-12.79-52.519-6.91-91.895-5.905-97.825 1.784-25.33 7.865-50.056 18.067-73.476 23.847-54.78 67.596-96.996 123.205-118.883 55.609-21.887 116.395-20.781 171.175 3.041 41.889 18.218 76.717 48.246 100.765 86.793 23.571 37.794 35.18 81.09 33.597 125.216-2.035 56.438-31.938 136.497-43.296 162.581-7.664 17.59-3.619 30.732 1.106 38.673 33.094 55.458-1.106 97.046-18.018 109.208-4.774 4.297-27.063 21.962-70.183 21.962v.025zM24.111 364.544c-.227 16.032 1.155 38.848 7.664 65.083C48.71 497.901 90.55 555.822 156.16 601.782c80.913 56.69 136.045 66.992 168.009 65.686 33.496-1.382 48.422-15.203 49.025-15.781l.754-.754.779-.502c.402-.277 41.01-29.049 11.836-77.924-6.207-10.428-14.801-32.114-2.513-60.384.377-.904 39.075-91.392 41.336-153.912 1.408-39.376-8.945-77.998-30.003-111.771-21.46-34.451-52.619-61.263-90.061-77.547-48.95-21.308-103.253-22.263-152.932-2.713-49.679 19.55-88.753 57.267-110.062 106.218-9.197 21.133-14.65 43.447-16.183 66.289l-1.759 26.184-.276-.277v-.05z"
            ></path>
            <path
              fill={"#fff"}
              d="M336.86 497.222c-2.94 0-5.805-.05-8.594-.126-54.077-1.658-90.488-17.816-95.891-20.329-23.922-8.468-46.136-20.907-66.012-36.964-46.463-37.567-75.511-90.965-81.819-150.394-6.282-59.428 10.931-117.726 48.498-164.189 28.722-35.532 66.918-61.112 110.49-74.029 42.694-12.664 87.523-12.338 129.638.93 53.851 16.962 123.079 67.093 145.193 84.985 14.926 12.061 28.671 11.659 37.567 9.222 62.243-17.138 93.251 26.887 100.488 46.437 5.529 11.082 41.588 97.926-112.073 224.825-78.727 65.007-154.892 79.657-207.511 79.657l.026-.025zm-76.064-35.23c15.378 4.824 39.527 10.529 69.354 11.308 70.285 1.834 137.277-23.093 199.043-74.104 76.19-62.922 100.79-113.279 108.027-144.464 7.564-32.642-1.759-50.709-2.161-51.463l-.528-.93-.276-.904c-.176-.478-17.087-47.242-71.968-32.14-11.685 3.217-34.903 5.73-58.876-13.67-.754-.603-77.697-61.992-137.377-80.788-37.567-11.835-77.572-12.112-115.717-.829-38.924 11.534-73.048 34.401-98.73 66.163-33.546 41.513-48.95 93.604-43.321 146.7 5.629 53.072 31.586 100.791 73.099 134.362 17.941 14.5 37.969 25.682 59.579 33.245l20.304 7.112-.477.377.025.025z"
            ></path>
            <path
              fill={"#fff"}
              d="M338.367 581.227c-57.343 0-102.926-14.324-135.141-29.476-51.588-24.299-79.933-55.182-83.954-59.756-19.525-18.168-35.733-39.451-48.171-63.273-29.074-55.635-34.728-119.285-15.932-179.166C73.966 189.649 114.95 140.674 170.61 111.6c42.542-22.213 90.085-30.908 137.452-25.128 46.438 5.679 89.533 24.751 124.562 55.157 53.197 46.186 104.032 141.9 104.51 142.855 9.473 18.143 23.143 23.621 32.918 25.003 66.917 9.499 78.3 64.681 77.094 86.392.679 12.64-.729 111.545-202.158 169.642-39 11.233-74.632 15.706-106.621 15.706zm-187.458-91.669c12.765 11.182 34.124 27.415 63.5 41.11 67.319 31.36 142.679 35.381 223.97 11.961 100.313-28.923 145.318-67.419 165.42-94.634 21.083-28.546 19.601-50.056 19.525-50.96l-.1-1.056.1-.954c.051-.528 3.342-53.424-56.614-61.917-12.539-1.784-35.783-9.021-50.684-37.567-.478-.905-49.126-92.599-99.006-135.895-31.436-27.289-70.109-44.402-111.822-49.503-42.568-5.202-85.286 2.613-123.532 22.59-50.006 26.109-86.844 70.134-103.73 123.959-16.887 53.825-11.81 110.993 14.323 160.998 11.283 21.611 26.008 40.884 43.774 57.293l15.554 14.374-.653.176-.025.025z"
            ></path>
          </svg>
          <nav>
            <h4>Bienvenido</h4>
            <ul>
              <li>
                <Link to="/dashboard">Dashboard</Link>
              </li>
              <li>
                <Link to="/campaigns">Campañas</Link>
              </li>
              <li>
                <Link to="/search">Buscar</Link>
              </li>
            </ul>
            <button className="button" onClick={handleLogout}>
              Cerrar Sesión
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  fill="#292D32"
                  d="M15.24 22.27h-.13c-4.44 0-6.58-1.75-6.95-5.67-.04-.41.26-.78.68-.82.4-.04.78.27.82.68.29 3.14 1.77 4.31 5.46 4.31h.13c4.07 0 5.51-1.44 5.51-5.51V8.74c0-4.07-1.44-5.51-5.51-5.51h-.13c-3.71 0-5.19 1.19-5.46 4.39-.05.41-.4.72-.82.68a.751.751 0 01-.69-.81c.34-3.98 2.49-5.76 6.96-5.76h.13c4.91 0 7.01 2.1 7.01 7.01v6.52c0 4.91-2.1 7.01-7.01 7.01z"
                ></path>
                <path
                  fill="#292D32"
                  d="M15 12.75H3.62c-.41 0-.75-.34-.75-.75s.34-.75.75-.75H15c.41 0 .75.34.75.75s-.34.75-.75.75z"
                ></path>
                <path
                  fill="#292D32"
                  d="M5.85 16.1c-.19 0-.38-.07-.53-.22l-3.35-3.35a.754.754 0 010-1.06l3.35-3.35c.29-.29.77-.29 1.06 0 .29.29.29.77 0 1.06L3.56 12l2.82 2.82c.29.29.29.77 0 1.06-.14.15-.34.22-.53.22z"
                ></path>
              </svg>
            </button>
          </nav>
        </>
      )}
    </div>
  );
};

export default Sidebar;

import React, { useState, useEffect } from "react";
import { getLeads, getCampaigns } from "../services/api";

function LeadList() {
  const [leads, setLeads] = useState([]);
  const [campaigns, setCampaigns] = useState([]);
  const [selectedCampaign, setSelectedCampaign] = useState("");

  useEffect(() => {
    fetchLeads();
    fetchCampaigns();
  }, []);

  const fetchLeads = async () => {
    try {
      const response = await getLeads({ campana: selectedCampaign });
      setLeads(response.data);
    } catch (error) {
      console.error("Error fetching leads", error);
    }
  };

  const fetchCampaigns = async () => {
    try {
      const response = await getCampaigns();
      setCampaigns(response.data);
    } catch (error) {
      console.error("Error fetching campaigns", error);
    }
  };

  const handleCampaignChange = (e) => {
    setSelectedCampaign(e.target.value);
    fetchLeads();
  };

  return (
    <div>
      <h1>Leads</h1>
      <select value={selectedCampaign} onChange={handleCampaignChange}>
        <option value="">Todos los clientes</option>
        {campaigns.map((campaign) => (
          <option key={campaign.id} value={campaign.nombre}>
            {campaign.nombre}
          </option>
        ))}
      </select>
      <table>
        <thead>
          <tr>
            <th>ID</th>
            <th>Cliente</th>
            <th>Fecha</th>
            <th>Estado</th>
          </tr>
        </thead>
        <tbody>
          {leads.map((lead) => (
            <tr key={lead.id}>
              <td>{lead.id}</td>
              <td>{lead.campana}</td>
              <td>{new Date(lead.fecha).toLocaleString()}</td>
              <td>{lead.status === 1 ? "Recibido" : "Pendiente"}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default LeadList;
